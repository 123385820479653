import React, { useEffect, useState } from "react";
import {
  useCreateReviewMutation,
  useFetchAllForSearchQuery,
} from "../../store/slice/CollegeSlice";

const CreateReview = () => {
  const [facultyRating, setFacultyRating] = useState(0);
  const [placementRating, setPlacementRating] = useState(0);
  const [campusRating, setCampusRating] = useState(0);
  const [studentLifeRating, setStudentLifeRating] = useState(0);
  const [generalRating, setGeneralRating] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [createReview, { isLoading }] = useCreateReviewMutation();
  const [selectedCollegeId, setSelectedCollegeId] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // Fetch colleges based on search query
  const { data: colleges = [], isLoading: isFetchingColleges } =
    useFetchAllForSearchQuery(searchQuery);

  useEffect(() => {
    if (selectedCollege && Array.isArray(colleges)) {
      const college = colleges.find(
        (college) => college._id === selectedCollege._id
      );
      if (college) {
        setSelectedCollegeId(college._id);
      } else {
        setSelectedCollegeId("");
      }
    }
  }, [selectedCollege, colleges]);

  const calculateAverageRating = () => {
    const ratings = [
      facultyRating,
      placementRating,
      campusRating,
      studentLifeRating,
    ];
    const sum = ratings.reduce((a, b) => a + b, 0);
    return (ratings.length ? sum / ratings.length : 0).toFixed(1); // Fix to 1 decimal place
  };

  const handleSubmit = async () => {
    if (!selectedCollegeId) {
      alert("Please select a college");
      return;
    }
    try {
      const averageRating = calculateAverageRating();
      const data = {
        CollegeId: selectedCollegeId,
        Facultyrating: facultyRating,
        Placementrating: placementRating,
        Campusrating: campusRating,
        StudentLiferating: studentLifeRating,
        generalRating,
        averageRating,
      };
      console.log(data);

      const response = await createReview(data).unwrap();
      console.log("Review created successfully", response);
      setShowSuccessPopup(true);

      // Hide the popup after 3 seconds
      setTimeout(() => {
        setShowSuccessPopup(false);
      }, 3000);
      // Reset form fields
      setFacultyRating(0);
      setPlacementRating(0);
      setCampusRating(0);
      setStudentLifeRating(0);
      setGeneralRating("");
      setSelectedCollege(null); // Reset selected college
      setSelectedCollegeId(""); // Reset selected college ID
      setSearchQuery("");
    } catch (error) {
      console.error("Failed to create review:", error);
    }
  };

  return (
    <div className="bg-gray-100 py-12">
      <div className="mx-auto max-w-3xl rounded-lg shadow-lg bg-white p-8">
      {showSuccessPopup && (
        <div className="fixed inset-0 absolute top-0 flex items-center justify-center z-50">
          <div className="bg-green-500 text-white font-bold py-4 px-8 rounded-lg shadow-lg">
            Review created successfully!
          </div>
        </div>
      )}
        {/* Header Section */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Make Your Opinion Count!
          </h2>
          <p className="text-gray-700 mb-4">
            Your experience can guide thousands of students. Share your insights
            and help others find their path.
          </p>
          <div className="inline-flex items-center bg-yellow-100 p-2 rounded-lg">
            <img
              src="https://imgs.search.brave.com/dHmhbGDf-O9KQ2pvAQvxtsdy3eYgpIWmby1tL3g_4Lg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA4LzUxLzE4LzQx/LzM2MF9GXzg1MTE4/NDEzNF9peWxUOUxo/TXpTNDZSYl"
              alt="Rewards"
              className="h-6 w-6 mr-2"
            />
            <p className="text-yellow-600 font-semibold">
              Leave a review for a chance to win exciting rewards!
            </p>
          </div>
        </div>

        {/* Dynamic Progress Bar */}
        <div className="w-full bg-gray-300 rounded-full h-2 mb-8">
          <div
            className="bg-yellow-400 h-2 rounded-full"
            style={{ width: "50%" }}
          ></div>
        </div>

        {/* Search Bar Section */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">
            Search College:
          </h3>
          <input
            type="text"
            className="p-4 border border-gray-300 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-200 ease-in-out"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Type to search for colleges..."
            disabled={isFetchingColleges}
          />
        </div>

        {/* Search Results Section */}
        {searchQuery && colleges.length > 0  && !selectedCollege &&(
          <div className="mb-8">
            <ul className="space-y-2">
              {colleges.map((college) => (
                <li
                  key={college._id}
                  className={`p-4 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 ${
                    selectedCollege?._id === college._id ? "bg-yellow-100" : ""
                    
                  }`}
                  onClick={() => {
                    setSelectedCollegeId(college._id);
                    setSearchQuery(college.name);
                  }}
                >
                  {college.name}
                </li>
                
              ))}
            </ul>
          </div>
        )}

        {/* Interactive Rating Section */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">
            Rate Your Experience:
          </h3>
          <div className="space-y-4">
            {[
              {
                label: "Faculty",
                value: facultyRating,
                setValue: setFacultyRating,
              },
              {
                label: "Placement",
                value: placementRating,
                setValue: setPlacementRating,
              },
              {
                label: "Campus",
                value: campusRating,
                setValue: setCampusRating,
              },
              {
                label: "Academic ",
                value: studentLifeRating,
                setValue: setStudentLifeRating,
              },
            ].map(({ label, value, setValue }) => (
              <div key={label}>
                <label className="block text-center mb-2 font-semibold text-gray-800">
                  {label} Rating
                </label>
                <div className="flex items-center justify-center">
                  {[...Array(5)].map((_, index) => (
                    <span
                      key={index}
                      className={`text-4xl cursor-pointer transition duration-200 ease-in-out ${
                        index < value ? "text-yellow-400" : "text-gray-300"
                      }`}
                      onClick={() => setValue(index + 1)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* General Rating Section */}
        <div className="mb-8">
          <label className="block text-center mb-2 font-semibold text-gray-800">
            General Rating
          </label>
          <textarea
            className="p-4 border border-gray-300 rounded-lg w-full shadow-sm pr-20 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-200 ease-in-out resize-none"
            rows="3"
            value={generalRating}
            onChange={(e) => setGeneralRating(e.target.value)}
            placeholder="Describe your overall experience..."
          />
        </div>
       
        <button
          className="h-12 bg-yellow-400 text-white rounded-lg shadow-md hover:bg-yellow-500 transition duration-200 ease-in-out w-full"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit Review"}
        </button>
      </div>
    </div>
  );
};

export default CreateReview;
