import React, { useState, useRef } from "react";
import logo from "../../assets/logo/logo.png";
import { PrimaryMenu } from "../../json/Menus";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [subDropdownOpen, setSubDropdownOpen] = useState(null);
  const [subItemsToShow, setSubItemsToShow] = useState({});
  const timeoutRef = useRef(null);

  const handleMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(null);
      setSubDropdownOpen(null);
    }, 150);
  };

  const handleSubMenuMouseEnter = (index) => {
    setSubDropdownOpen(index);
  };

  const handleSubMenuMouseLeave = () => {
    setSubDropdownOpen(null);
  };

  const loadMoreSubItems = (menuIndex) => {
    setSubItemsToShow((prev) => ({
      ...prev,
      [menuIndex]: (prev[menuIndex] || 10) + 10,
    }));
  };

  return (
    <nav className="px-5 shadow-lg bg-white border-b-2 border-gray-100 grid lg:grid-cols-5 grid-cols-1 items-center">
      <div className="flex">
        <Link to="/">
          <img src={logo} alt="logo" className="lg:w-[100px] w-[70px]" />
        </Link>
        <button onClick={() => setSidebar(true)} className="ml-auto lg:hidden ">
          <GiHamburgerMenu />
        </button>
      </div>
      <div className="m-auto lg:flex gap-8 col-span-3 hidden">
        {PrimaryMenu?.map((item, index) => (
          <div
            key={item?.Name}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            className="relative group"
          >
            <Link
              to={item?.Url}
              className="text-gray-800 hover:text-blue-600 font-semibold transition-colors duration-300"
            >
              {item?.Name}
            </Link>
            {item?.SubMenu && dropdownOpen === index && (
              <div
                className="absolute left-0 mt-3 w-56 bg-white shadow-xl rounded-lg transition-all duration-300 opacity-0 group-hover:opacity-100 transform group-hover:translate-y-1"
                style={{ zIndex: "10000" }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <ul className="py-2">
                  {item?.SubMenu.slice(0, subItemsToShow[index] || 10).map(
                    (subItem, subIndex) => (
                      <li
                        key={subItem?.Name}
                        className="relative"
                        onMouseEnter={() => handleSubMenuMouseEnter(subIndex)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        <Link
                          to={subItem?.Url}
                          className="block px-4 py-3 text-gray-800 hover:bg-gray-200 hover:text-blue-700 transition-all duration-300 rounded-lg"
                        >
                          {subItem?.Name}
                        </Link>
                        {subItem?.SubMenu && subDropdownOpen === subIndex && (
                          <div
                            className="absolute left-full top-0 mt-0 w-56 bg-white shadow-xl rounded-lg transition-all duration-300"
                            style={{ zIndex: "10000" }}
                            onMouseEnter={() =>
                              handleSubMenuMouseEnter(subIndex)
                            }
                            onMouseLeave={handleSubMenuMouseLeave}
                          >
                            <ul className="py-2">
                              {subItem?.SubMenu.slice(
                                0,
                                subItemsToShow[subIndex] || 10
                              ).map((nestedItem) => (
                                <li key={nestedItem?.Name}>
                                  <Link
                                    to={nestedItem?.Url}
                                    className="block px-4 py-3 text-gray-800 hover:bg-gray-200 hover:text-blue-700 transition-all duration-300 rounded-lg"
                                  >
                                    {nestedItem?.Name}
                                  </Link>
                                </li>
                              ))}
                              {subItem?.SubMenu.length > 10 && (
                                <button
                                  onClick={() => loadMoreSubItems(subIndex)}
                                  className="block px-4 py-2 text-blue-600 hover:text-blue-800 font-semibold transition-colors duration-300"
                                >
                                  Read More
                                </button>
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    )
                  )}
                  {item?.SubMenu.length > 10 && (
                    <button
                      onClick={() => loadMoreSubItems(index)}
                      className="block px-4 py-2 text-blue-600 hover:text-blue-800 font-semibold transition-colors duration-300"
                    >
                      Read More
                    </button>
                  )}
                </ul>
              </div>
            )}
          </div>
        ))}
            <Link to = {"/login"} className="bg-red-700 rounded-lg mx-8 px-3 py-[.9px] text-white font-bold">Login
            </Link>
      </div>

      {/* Mobile Menu */}
      <MobileMenu setSidebar={setSidebar} sidebar={sidebar} />
    </nav>
  );
};

export default Header;
