import { BrowserRouter, Route, Routes } from "react-router-dom";
import {MainRoute} from "./json/MainRoute";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {MainRoute?.map((item,index) => (
          <Route path={item?.Url} Component={item?.Component}  key={index}/>
        ))}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
