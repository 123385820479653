import { configureStore } from '@reduxjs/toolkit'
import { articleApiReducer, articleApiSlice } from './slice/ArticleSlice'
import { collegeApiReducer, collegeApiSlice } from './slice/CollegeSlice'

export const store = configureStore({
    reducer: {
        articlesApi: articleApiReducer,
        collegeApi: collegeApiReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            articleApiSlice.middleware,
            collegeApiSlice.middleware
        ),
})