import Article from "../pages/Article";
import Home from "../pages/Home";
import ArticlesByCategory from "../pages/ArticlesByCategory";
import Colleges from "../pages/Colleges";
import CollegeView from "../pages/CollegeView";
import UgPgComponent from "../components/article/UgPgComponent";
import ComingSoon from "../pages/ComingSoon";
import CreateReview from "../components/review/CreateReview";
import CollegeReview from "../components/review/CollegeReview";
import CollegeCompare from "../components/college/CollegeCompare";
import Login from "../components/login/Login";
import Register from "../components/login/Register";



export const MainRoute = [
    {
        Name: 'Home',
        Component: Home,
        Url:'/'
    },
    {
        Name: 'Article',
        Component: Article,
        Url:'/article/:slug'
    },
    {
        Name: 'Article',
        Component: ArticlesByCategory,
        Url:'/article/category/:id'
    },
    {
        Name: 'College',
        Component: Colleges,
        Url:'/allcolleges/'
    },
    {
        Name: 'College',
        Component: CollegeView,
        Url:'/college/:slug'
    },
    {
        Name: 'UgPgComponent',
        Component:UgPgComponent,
        Url:'/article/colleges/courses/:name/:id'
    },
    {
        Name:'Coming',
        Component:ComingSoon,
        Url:'/comingSoon'
    },
    {
        Name:'review',
        Component:CreateReview,
        Url:'/reviewCreate'
    },
    {
        Name:'collegeReview',
        Component:CollegeReview,
        Url:'/review/college/:collegeId'
    },
   
    {
        Name:'CompareColleges',
        Component:CollegeCompare,
        Url:'/compare/college'
    },
    {
        
        Name:'login',
        Component:Login,
        Url:'/login'
    },
    {
        Name:'register',
        Component:Register,
        Url:'/register'
    },
   
  
  
  
]

