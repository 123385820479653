import React, { Suspense } from 'react'
import Loader from '../utils/Loader'
import {EntranceExams, ExploreCourses} from '../components/home/ExploreCourses';
import TrendingNews from '../components/home/TrendingNews';
import Scholarship from '../components/home/Scholarship';
import Faq from '../components/home/Faq';
import Search from '../components/home/Search';
import ReviewBanner from '../components/home/ReviewBanner';

const HomeSlider = React.lazy(() => import('../components/home/HomeSlider'), 'HomeSlider');
const HomeTabSwitcher = React.lazy(() => import('../components/home/HomeTabSwitcher'), 'HomeTabSwitcher');
const UgPgTabSwitcher = React.lazy(() => import('../components/home/UgPgTabSwitcher'), 'UgPgTabSwitcher');
const HomeTopColleges = React.lazy(() => import('../components/home/HomeTopColleges'), 'HomeTopColleges');
const LatestArticle = React.lazy(() => import('../components/home/LatestArticle'), 'LatestArticle');


const Home = () => {
  window.scroll(0,0);
  return (
    <div style={{width:"fit-content"}}>
      <Suspense fallback={<Loader />}>
      <HomeSlider/>
      <Search/>
      <HomeTabSwitcher/>
      <ExploreCourses/>
      <UgPgTabSwitcher/>
      <ReviewBanner/>
      <HomeTopColleges/>
      <EntranceExams/>
      <LatestArticle/>
      <TrendingNews/>
      <Scholarship/>
      <Faq/>
      </Suspense>
    </div>
  )
}

export default Home
