import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchAllReviewsQuery } from '../../store/slice/CollegeSlice';
import StarRating from './StarRating';
import { FaStar, FaFilter, FaThumbsUp, FaThumbsDown, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const CollegeReview = () => {
    const { collegeId } = useParams();
    const { data: response, isLoading, isError } = useFetchAllReviewsQuery(collegeId);
    const reviews = response?.reviews || [];
    // State for filtering reviews
    const [filter, setFilter] = useState('all');

    // State to toggle review details visibility
    const [expandedReview, setExpandedReview] = useState(null);

    // State for like/dislike
    const [likes, setLikes] = useState({});
    const [dislikes, setDislikes] = useState({});

    // Filtered reviews based on user selection
    const filteredReviews = filter === 'all' ? reviews : reviews.filter(review => review.generalRating === parseInt(filter));

    // Calculate average ratings
    const averageRating = (key) => {
        if (reviews.length === 0) return 0; // Avoid division by zero if there are no reviews
        const total = reviews.reduce((acc, review) => acc + (review[key] || 0), 0); // Handle cases where the key might not exist
        return (total / reviews.length).toFixed(1);
    };
    

    // Handle loading and error states
    if (isLoading) return <div className="text-center py-12 text-gray-500">Loading...</div>;
    if (isError) return <div className="text-center py-12 text-red-500 text-3xl">No Reviews Found</div>;

    // Toggle review details
    const toggleDetails = (reviewId) => {
        setExpandedReview(expandedReview === reviewId ? null : reviewId);
    };

    // Handle like/dislike clicks
    const handleLike = (reviewId) => {
        setLikes({ ...likes, [reviewId]: (likes[reviewId] || 0) + 1 });
    };

    const handleDislike = (reviewId) => {
        setDislikes({ ...dislikes, [reviewId]: (dislikes[reviewId] || 0) + 1 });
    };

    return (
        <div className="p-6 max-w-6xl mx-auto">
            <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-800">Reviews for College </h2>
            
            {/* Filter Dropdown */}
            {/* <div className="flex justify-end mb-6">
                <div className="relative">
                    <select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="appearance-none border border-gray-300 rounded-md py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-gray-500"
                    >
                        <option value="all">All Ratings</option>
                        {[5, 4, 3, 2, 1].map(rating => (
                            <option key={rating} value={rating}>{rating} Stars</option>
                        ))}
                    </select>
                    <FaFilter className="absolute top-0 right-0 mt-3 mr-3 text-gray-400" />
                </div>
            </div> */}

            {/* Average Ratings Section */}
            <div className="mb-10">
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 text-center">
                
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Faculty Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Facultyrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Placement Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Placementrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Campus Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Campusrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">StudentLife Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('StudentLiferating')}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Reviews List */}
            {filteredReviews.length > 0 ? (
                <ul className="space-y-6">
                {filteredReviews.map((review) => (
                    <li key={review._id} className="p-6 border grid grid-cols-2 rounded-lg shadow-lg bg-white transition duration-300 hover:shadow-2xl hover:bg-blue-50">
                        <div className="text-center mb-4">
                            <h4 className="text-lg font-semibold text-gray-800">Overall Experience</h4>
                            <div className="flex justify-center items-center mt-2">
                                <h3 className="text-xl font-bold flex items-center">
                                    <FaStar className="mr-1" />
                                    {review.generalRating}
                                </h3>
                                <button 
                                    onClick={() => toggleDetails(review._id)} 
                                    className="ml-4 text-gray-500 hover:text-blue-600 transition"
                                    aria-label="Toggle Review Details"
                                >
                                    {expandedReview === review._id ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                            </div>
                        </div>
                        {expandedReview === review._id && (
                            <div className="text-lg text-gray-700 space-y-3 text-center">
                                <div className="flex justify-center items-center">
                                    <strong className="w-40">Faculty Rating:</strong> 
                                    <StarRating rating={review.Facultyrating} />
                                </div>
                                <div className="flex justify-center items-center">
                                    <strong className="w-40">Placement Rating:</strong> 
                                    <StarRating rating={review.Placementrating} />
                                </div>
                                <div className="flex justify-center items-center">
                                    <strong className="w-40">Campus Rating:</strong> 
                                    <StarRating rating={review.Campusrating} />
                                </div>
                                <div className="flex justify-center items-center">
                                    <strong className="w-40">Student Life Rating:</strong> 
                                    <StarRating rating={review.StudentLiferating} />
                                </div>
                            </div>
                        )}
                        <div className="flex justify-center items-center mt-4 space-x-6">
                            <button 
                                onClick={() => handleLike(review._id)} 
                                className="flex items-center text-green-500 hover:text-green-700 transition"
                                aria-label="Like Review"
                            >
                                <FaThumbsUp className="mr-2 text-4xl" />
                                <span>{likes[review._id] || 0}</span>
                            </button>
                            <button 
                                onClick={() => handleDislike(review._id)} 
                                className="flex items-center text-red-500 hover:text-red-700 transition"
                                aria-label="Dislike Review"
                            >
                                <FaThumbsDown className="mr-2 text-4xl" />
                                <span>{dislikes[review._id] || 0}</span>
                            </button>
                        </div>
                        <div className="text-center text-gray-500 mt-4">
                            <span>Was this review helpful?</span>
                            <div className="flex justify-center items-center space-x-2 mt-2">
                                <button 
                                    className="text-blue-500 hover:text-blue-700 transition"
                                    aria-label="Mark as Helpful"
                                >
                                    Yes
                                </button>
                                <button 
                                    className="text-blue-500 hover:text-blue-700 transition"
                                    aria-label="Mark as Not Helpful"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            
            ) : (
                <p className="text-center text-lg text-gray-500">No reviews available.</p>
            )}
        </div>
    );
};

export default CollegeReview;
