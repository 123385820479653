import React, { useState, useEffect } from "react";

import {
  useFetchAllForSearchQuery,
  useFetchAllCoursesQuery,
  useFetchAllSpeciliazationByCourseQuery
} from "../../store/slice/CollegeSlice";
import { motion } from "framer-motion";

const storage = process.env.REACT_APP_S3_BUCKET;

const CollegeCompare = () => {
  const [selectedColleges, setSelectedColleges] = useState([null, null, null, null]);
  const [searchQueries, setSearchQueries] = useState(["", "", "", ""]);
  const [collegeCourses, setCollegeCourses] = useState([[], [], [], []]);
  const [selectedCourses, setSelectedCourses] = useState([null, null, null, null]);
  // const [selectedSpecializations, setSelectedSpecializations] = useState([[], [], [], []]);

  // Fetch search results for each search query
  const { data: searchResults1 = [] } = useFetchAllForSearchQuery(searchQueries[0], { skip: !searchQueries[0] });
  const { data: searchResults2 = [] } = useFetchAllForSearchQuery(searchQueries[1], { skip: !searchQueries[1] });
  const { data: searchResults3 = [] } = useFetchAllForSearchQuery(searchQueries[2], { skip: !searchQueries[2] });
  const { data: searchResults4 = [] } = useFetchAllForSearchQuery(searchQueries[3], { skip: !searchQueries[3] });

  const searchResults = [searchResults1, searchResults2, searchResults3, searchResults4];

  // Fetch courses for each selected college
  const coursesQuery1 = useFetchAllCoursesQuery(selectedColleges[0]?._id, { skip: !selectedColleges[0]?._id });
  const coursesQuery2 = useFetchAllCoursesQuery(selectedColleges[1]?._id, { skip: !selectedColleges[1]?._id });
  const coursesQuery3 = useFetchAllCoursesQuery(selectedColleges[2]?._id, { skip: !selectedColleges[2]?._id });
  const coursesQuery4 = useFetchAllCoursesQuery(selectedColleges[3]?._id, { skip: !selectedColleges[3]?._id });

  useEffect(() => {
    const updatedCourses = [
      coursesQuery1.data?.Course || [],
      coursesQuery2.data?.Course || [],
      coursesQuery3.data?.Course || [],
      coursesQuery4.data?.Course || [],
    ];
    setCollegeCourses(updatedCourses);
  }, [coursesQuery1.data, coursesQuery2.data, coursesQuery3.data, coursesQuery4.data, selectedColleges]);

  const handleSelectCollege = (index, college) => {
    const newSelection = [...selectedColleges];
    newSelection[index] = college;
    setSelectedColleges(newSelection);
    setSearchQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      newQueries[index] = "";
      return newQueries;
    });
  };

  const handleSearchChange = (index, value) => {
    const newQueries = [...searchQueries];
    newQueries[index] = value;
    setSearchQueries(newQueries);
  };

  // Handle course selection
  const handleSelectCourse = (index, courseId) => {
    const updatedSelectedCourses = [...selectedCourses];
    updatedSelectedCourses[index] = courseId;
    setSelectedCourses(updatedSelectedCourses);
  };

  // Fetch specializations for each course individually
  // const { data: specializations1 = [] } = useFetchAllSpeciliazationByCourseQuery(selectedCourses[0], {
  //   skip: !selectedCourses[0],
  // });
  // const { data: specializations2 = [] } = useFetchAllSpeciliazationByCourseQuery(selectedCourses[1], {
  //   skip: !selectedCourses[1],
  // });
  // const { data: specializations3 = [] } = useFetchAllSpeciliazationByCourseQuery(selectedCourses[2], {
  //   skip: !selectedCourses[2],
  // });
  // const { data: specializations4 = [] } = useFetchAllSpeciliazationByCourseQuery(selectedCourses[3], {
  //   skip: !selectedCourses[3],
  // });

  // useEffect(() => {
  //   // console.log('Specializations fetched:', {
  //   //   specializations1,
  //   //   specializations2,
  //   //   specializations3,
  //   //   specializations4
  //   // });
  
  //   const updatedSpecializations = [
  //     specializations1?.specialization || [],
  //     specializations2?.specialization || [],
  //     specializations3?.specialization || [],
  //     specializations4?.specialization || [],
  //   ];
  //   // console.log('Updated Specializations:', updatedSpecializations[0]);
  //   setSelectedSpecializations(updatedSpecializations);
  // }, [specializations1, specializations2, specializations3, specializations4]);
  
  // console.log(specializations1?.specialization?.[0].specializationName);
  return (
    <div className="mx-auto max-w-7xl p-8 bg-gradient-to-r from-blue-50 to-teal-50">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
          Select Colleges for Comparison
        </h2>
        <p className="text-lg text-gray-700 italic">
          "Education is the most powerful weapon which you can use to change the world." - Nelson Mandela
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
        {selectedColleges.map((college, index) => (
          <motion.div
            key={index}
            className="relative border rounded-xl shadow-lg p-6 flex flex-col items-center bg-white"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-bold mb-4 text-gray-900">
              Search College {index + 1}
            </h3>
            <input
              type="text"
              placeholder="Search College..."
              value={searchQueries[index]}
              onChange={(e) => handleSearchChange(index, e.target.value)}
              className="mb-2 p-2 border border-gray-300 rounded-full w-full bg-transparent text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {searchQueries[index] && (
              <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg max-h-40 overflow-y-auto z-10 shadow-lg mt-2">
                {searchResults[index].map((filteredCollege) => (
                  <li
                    key={filteredCollege._id}
                    onClick={() => handleSelectCollege(index, filteredCollege)}
                    className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white transition-colors"
                  >
                    {filteredCollege.name}
                  </li>
                ))}
              </ul>
            )}

            {/* Course Selection Dropdown */}
            {selectedColleges[index] && (
              <div className="mt-4 w-full">
                <label className="block text-gray-700 font-bold mb-2">
                  Select Course:
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-md"
                  value={selectedCourses[index] || ""}
                  onChange={(e) => handleSelectCourse(index, e.target.value)}
                >
                  <option value="" disabled>Select a course</option>
                  {collegeCourses[index]?.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.courseName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Specialization Selection Dropdown */}
            {/* Uncomment and adjust as needed
            {selectedSpecializations[index]?.length > 0 ? (
              <div className="mt-4 w-full">
                <label className="block text-gray-700 font-bold mb-2">
                  Select Specialization:
                </label>
                <select className="w-full border border-gray-300 p-2 rounded-md">
                  <option value="" disabled>Select a specialization</option>
                  {selectedSpecializations[index]?.map((specialization) => (
                    <option key={specialization._id} value={specialization._id}>
                      {specialization.specializationName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="mt-4 w-full">
                <p className="text-gray-600">No specializations available</p>
              </div>
            )}
            */}
          </motion.div>
        ))}
      </div>

      <div className="mt-12">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-900">
          College Comparison
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {selectedColleges.map((college, index) => {
            if (!college) return null;

            const colors = ["blue", "green", "yellow", "red"];
            const color = colors[index % colors.length];

            const selectedCourse = collegeCourses[index]?.find(
              (course) => course._id === selectedCourses[index]
            );

            return (
              <motion.div
                key={college._id}
                className={`relative border rounded-xl shadow-lg p-6 bg-${color}-50 border-${color}-400 transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-2xl`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="mb-4">
                  {college.coverimage && (
                    <img
                      src={`${storage}${college.coverimage}`}
                      alt={college.name}
                      className="w-full h-40 object-cover rounded-lg shadow-md transition-transform duration-500 hover:scale-105"
                    />
                  )}
                </div>

           

                <h3 className="text-xl font-bold text-gray-800 mb-4">
                  {college.name}
                </h3>

                <div className="space-y-3">
                  <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                    <span className="text-gray-600">{college.subtitle}</span>
                  </div>
                  <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                    <span className="font-medium text-gray-700 w-1/3">
                      Rating: {college.rating}
                    </span>
                  </div>
                  <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                    <span className="font-medium text-gray-700 ">
                    {" "}
                    {typeof college.location[0] === "string" ? (
                      <span className=" text-gray-700 w-1/3">
                        Location: {college?.location[0]}
                      </span>
                    ) : (
                      <span className="text-gray-700 w-1/3">
                       Location: {college?.location[0].city + "," + college?.location[0].state}
                      </span>
                    )}
                  </span>
                  </div>

                  {/* Display selected course details */}
                  {selectedCourse && (
                   <div className="mt-4 rounded-lg ">
                   <h4 className="text-lg underline font-semibold text-gray-800 mb-4  pb-2">
                     Selected Course
                   </h4>
                   <div className="space-y-6">
                     <div className="flex items-center">
                       <span className="font-medium text-sm text-gray-700 w-1/3">Course Name:</span>
                       <p className="text-gray-600 text-sm">{selectedCourse.courseName}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium text-sm text-gray-700 w-1/3">Course Duration:</span>
                       <p className="text-gray-600  text-sm">{selectedCourse.duration}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium  text-sm text-gray-700 w-1/3">Fees:</span>
                       <p className="text-gray-600  text-sm">{selectedCourse.fees}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium  text-sm text-gray-700 w-1/3">Eligibility: </span>
                       <p className="text-gray-600  text-sm">{selectedCourse.eligibility}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium  text-sm text-gray-700 w-1/3">Mode Of Study:</span>
                       <p className="text-gray-600  text-sm">{selectedCourse.modeOfStudy}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium  text-sm text-gray-700 w-1/3">Cutoff:</span>
                       <p className="text-gray-600  text-sm">{selectedCourse.cutoff}</p>
                     </div>
                     <div className="flex items-center">
                       <span className="font-medium  text-sm text-gray-700 w-1/3">Course Level:</span>
                       <p className="text-gray-600  text-sm">{selectedCourse.courseLevel}</p>
                     </div>
                   </div>
                 </div>
                 
                  )}
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollegeCompare;
