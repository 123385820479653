import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Debounce from '../../utils/Debounce';
import { useFetchAllForSearchQuery } from '../../store/slice/CollegeSlice';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const debouncedQuery = Debounce(query, 300); 

  const { data: collegesResponse, error } = useFetchAllForSearchQuery(debouncedQuery, { skip: !debouncedQuery });
  
  useEffect(() => {
    if (!debouncedQuery) {
      setResults([]);
    } else if (collegesResponse) {
      setResults(collegesResponse);
    }
  }, [debouncedQuery, collegesResponse]);

  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className='m-auto'>
      <div className="bg-gradient-to-r from-rose-200 to-amber-200 p-4 m-6 rounded-lg shadow-xl">
        <input
          type="text"
          style={{ width: "100%" }}
          placeholder="Search Colleges, Latest Articles..."
          value={query}
          onChange={handleSearch}
          className="w-full p-2 bg-white border border-gray-300 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 rounded-lg shadow-sm transition-all duration-200 ease-in-out"
        />
      </div>

      <div className='m-6' style={{ overflowY: results.length > 0 ? "scroll" : "hidden", height: results.length > 0 ? "200px" : "auto" }}>
        {results.length > 0 ? (
          results.map((result, index) => (
            <Link key={index} to={result.title ? `/article/${result.slug}` : `/college/${result.slug}`}>
             
              <div className="border p-2 m-2">
                <strong>{result.title || result.name}</strong>
              </div>
            </Link>
          ))
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Search;
