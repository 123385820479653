import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const articleApiSlice = createApi({
  reducerPath: 'articlesApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),

  endpoints: (builder) => ({
    fetchArticlesByCategory: builder.query({
      query: (id) => `/article/category/all/${id}`,
    }),
    fetchArticlesBySlug: builder.query({
      query: (slug) => `/article/slug/${slug}`,
    }),

    fetchAllArticles: builder.query({
      query: () => `/article/all`,
    }),

  

  }),
});

export const { 
  useFetchArticlesByCategoryQuery,
  useFetchArticlesBySlugQuery,
  useFetchAllArticlesQuery,
  
} = articleApiSlice;

export const articleApiReducer = articleApiSlice.reducer;

