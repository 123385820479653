import React from 'react';
import { FaStar, FaTrophy } from 'react-icons/fa';

const ReviewBanner = () => {
  const postReview = () => {
    window.location.href = "/reviewCreate";
  };

  return (
    <div className="bg-white shadow-lg -mt-10 ml-6 mr-6 rounded-lg text-center py-4 px-6 sm:px-6 lg:px-10 my-4 border-2 border-gray-200">
      <div className="flex flex-col items-center mb-4">
        <FaTrophy className="h-12 w-12 text-yellow-500 mb-2" />
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">We Value Your Feedback</h1>
        <div className="flex mb-2">
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} className="h-5 w-5 text-yellow-400" />
          ))}
        </div>
        <p className="text-base text-gray-600 mb-4">Your opinions help us improve and serve you better. Please share your thoughts!</p>
        <button 
          className="bg-gradient-to-r from-red-500 to-red-700 hover:from-green-500 hover:to-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
          onClick={postReview}
        >
          Share Your Review
        </button>
      </div>
      <p className="text-lg font-semibold text-gray-800 mt-4">Your review could make a big difference!</p>
    </div>
  );
}

export default ReviewBanner;
